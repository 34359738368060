import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'

import Slider from 'react-slick'

import SEO from '~/components/seo'
import ProductForm from '~/components/ProductForm'
import { Container, TwoColumnGrid, GridItem } from '~/utils/styles'
import { ProductTitle, ProductDescription, SliderImage } from './styles'

const SliderCom = ({ data }) => {
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  const [slider1, setSlider1] = useState(null)
  const [slider2, setSlider2] = useState(null)

  useEffect(() => {
    setNav1(slider1)
    setNav2(slider2)
  }, [slider1, slider2])

  const product = data.shopifyProduct

  var settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.slider-nav',
    adaptiveHeight: true,
  }

  var settingsThumbs = {
    slidesToShow: product.images.length,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '10px',
  }

  return (
    <>
      <div className="thumbnail-slider-wrap">
        <Slider
          {...settingsMain}
          asNavFor={nav2}
          ref={slider => setSlider1(slider)}
        >
          {product.images.map(
            image =>
              image.localFile.childImageSharp && (
                <SliderImage
                  fluid={image.localFile.childImageSharp.fluid}
                  key={image.localFile.childImageSharp.fluid.src}
                  alt={product.title}
                />
              )
          )}
        </Slider>
      </div>
      {product.images.length > 1 && (
        <div className="thumbnail-slider-wrap">
          <Slider
            {...settingsThumbs}
            asNavFor={nav1}
            ref={slider => setSlider2(slider)}
          >
            {product.images.map(
              image =>
                image.localFile.childImageSharp && (
                  <SliderImage
                    fluid={image.localFile.childImageSharp.fluid}
                    key={image.localFile.childImageSharp.fluid.src}
                    alt={product.title}
                  />
                )
            )}
          </Slider>
        </div>
      )}
    </>
  )
}

const ProductPage = ({ data }) => {
  const product = data.shopifyProduct
  const first = product.images[0]
  return (
    <>
      <SEO title={product.title} description={product.description} />
      <Container>
        {first.localFile.childImageSharp.fluid.aspectRatio < 1.5 ? (
          <TwoColumnGrid>
            <GridItem>
              <SliderCom data={data} />
            </GridItem>
            <GridItem>
              <ProductTitle>{product.title}</ProductTitle>
              <ProductDescription
                dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
              />
              <ProductForm product={product} />
            </GridItem>
          </TwoColumnGrid>
        ) : (
          <>
            <SliderCom data={data} />
            <ProductTitle>{product.title}</ProductTitle>
            <ProductDescription
              dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
            />
            <ProductForm product={product} />
          </>
        )}
      </Container>
    </>
  )
}

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              src
              aspectRatio
            }
          }
        }
      }
    }
  }
`

export default ProductPage

import styled from '@emotion/styled'
import Image from 'gatsby-image'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export const ProductTitle = styled.h1`
  margin-bottom: 15px;
  word-wrap: break-word;
  margin: 0 0 0.5rem;
  line-height: 1.4;
`

export const ProductDescription = styled.div`
  margin-top: 40px;
`
export const SlideContainer = styled.div`
  display: block;
`

export const SliderImage = styled(Image)`
  display: block;
  max-width: 80rem;
  margin: 0 auto;
  margin-bottom: 2rem;
`
